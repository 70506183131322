<template>
  <div class="paySlips animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <div style="background-color: transparent !important">
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6"> </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="goToNewPaySlip()"
              height="48"
            >
              <v-icon class="me-1">add</v-icon>
              <h6 class="mt-1">ثبت فیش حقوقی</h6>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div>
        <div>
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mt-2 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="paySlips"
              empty-text="موردی برای نمایش وجود ندارد"
              empty-filtered-text="موردی برای نمایش وجود ندارد"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              tbody-tr-class="trClass"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(paidAmount)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ Number(data.value).toLocaleString() + " ریال " }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    class="purple-btn pa-2"
                    width="180"
                    style="margin-top: 2px"
                    @click="goToPaySlip(data.item.id)"
                    ><v-icon class="me-1">mdi-file-edit-outline</v-icon>
                    <span class="common-text"> مشاهده/ ویرایش</span>
                  </v-btn>
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(paySlips.length / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </div>
      </div>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  props: ["paySlips", "userData"],
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "startDate", label: "تاریخ شروع" },
        { key: "endDate", label: "تاریخ پایان" },
        { key: "paidAmount", label: "مبلغ قابل پرداخت" },
        { key: "operation", label: "عملیات" },
      ],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      deviceType: "",
      reactivity: false,
    };
  },

  methods: {
    goToNewPaySlip() {
      localStorage.setItem("userData", JSON.stringify(this.userData));
      this.$router.push("/admin/newPaySlip/" + this.$route.params.id);
    },
    goToPaySlip(id) {
      this.$router.push("/admin/editPaySlip/" + id);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {},
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.TotalRows = this.paySlips.length;
    this.CurrentPage = 1;
  },
};
</script>
